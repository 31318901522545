import React from "react";
import { Link } from "react-router-dom";

export default function Founder() {
  return (
    <div>
      <div className="mt-12">
        <div
          className="overflow-hidden rounded-2xl min-h-[60vh] p-8 relative z-0 flex items-end"
          style={{
            backgroundImage: "url('/assets/images/about.jpg')",
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
          }}
        ></div>

        <div className="mt-8 flex md:flex-row flex-col justify-center md:gap-12 gap-4 *:md:max-w-[600px] *:w-full">
          <div className=" flex flex-col gap-4 pt-12">
            <div
              className=" sm-heading flex font-medium relative"
              style={{
                lineHeight: 1.1,
              }}
            >
              ⁠Together, let’s build your tomorrow, Today
              <div className="absolute bottom-[100%] translate-x-[75%] -translate-y-[10px] right-[100%] big-heading lily rotate-180">
                "
              </div>
            </div>

            <div className="grid place-content-center">
              <img
                src="/assets/Members/Rohit.webp"
                alt=""
                className=" rounded-full"
              />
            </div>

            <div className=" flex flex-wrap content-center justify-between">
              <p className=" *:flex mini-text px-[6px]">
                <span className=" font-bold">Col Rohit Gogna</span>
                <span className="opacity-60 font-medium">Founder & MD</span>
              </p>
              <Link to={"https://www.linkedin.com/in/rohit-gogna-0993773a/"}>
                <p className=" font-medium">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width={24}
                    height={24}
                    color={"#292F36"}
                    fill={"none"}
                  >
                    <path
                      d="M4.5 9.5H4C3.05719 9.5 2.58579 9.5 2.29289 9.79289C2 10.0858 2 10.5572 2 11.5V20C2 20.9428 2 21.4142 2.29289 21.7071C2.58579 22 3.05719 22 4 22H4.5C5.44281 22 5.91421 22 6.20711 21.7071C6.5 21.4142 6.5 20.9428 6.5 20V11.5C6.5 10.5572 6.5 10.0858 6.20711 9.79289C5.91421 9.5 5.44281 9.5 4.5 9.5Z"
                      stroke="currentColor"
                      strokeWidth="1.5"
                    />
                    <path
                      d="M6.5 4.25C6.5 5.49264 5.49264 6.5 4.25 6.5C3.00736 6.5 2 5.49264 2 4.25C2 3.00736 3.00736 2 4.25 2C5.49264 2 6.5 3.00736 6.5 4.25Z"
                      stroke="currentColor"
                      strokeWidth="1.5"
                    />
                    <path
                      d="M12.326 9.5H11.5C10.5572 9.5 10.0858 9.5 9.79289 9.79289C9.5 10.0858 9.5 10.5572 9.5 11.5V20C9.5 20.9428 9.5 21.4142 9.79289 21.7071C10.0858 22 10.5572 22 11.5 22H12C12.9428 22 13.4142 22 13.7071 21.7071C14 21.4142 14 20.9428 14 20L14.0001 16.5001C14.0001 14.8433 14.5281 13.5001 16.0879 13.5001C16.8677 13.5001 17.5 14.1717 17.5 15.0001V19.5001C17.5 20.4429 17.5 20.9143 17.7929 21.2072C18.0857 21.5001 18.5572 21.5001 19.5 21.5001H19.9987C20.9413 21.5001 21.4126 21.5001 21.7055 21.2073C21.9984 20.9145 21.9985 20.4432 21.9987 19.5006L22.0001 14.0002C22.0001 11.515 19.6364 9.50024 17.2968 9.50024C15.9649 9.50024 14.7767 10.1531 14.0001 11.174C14 10.5439 14 10.2289 13.8632 9.995C13.7765 9.84686 13.6531 9.72353 13.505 9.63687C13.2711 9.5 12.9561 9.5 12.326 9.5Z"
                      stroke="currentColor"
                      strokeWidth="1.5"
                      strokeLinejoin="round"
                    />
                  </svg>
                </p>
              </Link>
            </div>
          </div>
          <div className="flex flex-col gap-2 justify-center">
            <div className=" rounded-2xl p-4 bg-bright text-light">
              <h1 className="med-text font-bold">Our Vision</h1>
              <p className=" mini-text mt-2">
                At AVINYA GREEN, our vision is to lead the global infrastructure
                sector by revolutionizing it with innovative, sustainable, and
                high-efficiency solutions. We are dedicated to surpassing client
                expectations, setting industry standards, and making a positive
                impact on communities worldwide.
              </p>
            </div>

            <div className=" rounded-2xl p-4 bg-bright text-light">
              <h1 className="med-text font-bold">Our Core Values</h1>
              <p className=" mini-text mt-2">
                Integrity, Innovation, Collaboration—these values are at the
                heart of everything we do. They guide our ethical practices,
                inspire creativity, and promote teamwork, ensuring we deliver
                outstanding results and build enduring partnerships.
              </p>
            </div>

            <div className=" rounded-2xl p-4 bg-bright text-light">
              <h1 className="med-text font-bold">Our Culture</h1>
              <p className=" mini-text mt-2">
                At AVINYA GREEN, we cultivate a vibrant and inclusive workplace
                culture that values diversity and champions work-life balance.
                We prioritize the growth and well-being of our team members,
                empowering them to excel professionally while contributing to
                our collective success.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
