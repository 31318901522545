import React from "react";

export default function Header() {
  return (
    <div className="">
      <h1 className="big-heading flex items-end tracking-tight font-medium">
        About Us
      </h1>

      <div className=" h-[2px] bg-dark w-full my-4" />

      <div className="flex lg:flex-row flex-col justify-between gap-4">
        <div className="flex justify-between gap-4 min-w-[40%]">
          <div className=" sm-text opacity-65 *:md:my-2 *:my-1">
            <p> Team Size </p>
            <p> Experience </p>
            <p> Address </p>
          </div>
          <div className=" sm-text *:md:my-2 *:my-1">
            <p> 20+ Members </p>
            <p> 50+ Projects </p>
            <p>
              A-1/256, <br /> Hari Mandir Marg, <br /> Janakpuri, New Delhi -
              110058
            </p>
          </div>
        </div>
        <div className=" md:max-w-[768px] *:flex">
          <span
            className="large-text font-medium md:-ml-[1px] mb-4"
            style={{
              lineHeight: 1.1,
            }}
          >
            Avinya means innovation, and green is our soul motto!
          </span>
          <span className=" sm-text mb-1">
            At Avinya Green Constructions, innovation and sustainability are at
            the core of our mission. We understand that traditional
            builder-delivered properties often fall short of meeting clients'
            unique needs, leading to costly and time-consuming remodels. Our
            comprehensive, eco-friendly construction solutions provide a
            seamless experience from start to finish. From initial concept and
            architecture to statutory approvals, soil testing, and structural
            design, we ensure every detail is meticulously handled. Our
            commitment to transparency and excellence guarantees that your dream
            property is realized without the usual stress and setbacks.
          </span>
          <span className=" sm-text">
            Our dedication extends beyond construction. We manage
            post-construction activities, including interiors, renting, leasing,
            security management, and marketing, ensuring your property is not
            only built to perfection but also maintained to the highest
            standards. Environmental preservation is embedded in our core
            values, and we set benchmarks in sustainability across all our
            projects. At Avinya Green Construction, we strive to make your
            construction journey smooth and rewarding, prioritizing your needs
            and the environment at every step. Trust us to bring your vision to
            life with integrity and unparalleled expertise.
          </span>
        </div>
      </div>

      {/* <div className=" mt-10">
        <h1 className=" large-text font-medium md:text-left text-center">
          Certificates / Collaboration
        </h1>
        <div className=" flex flex-wrap content-center justify-center mt-8 gap-4">
          {[...Array(6)].map((_, i) => {
            return (
              <div
                key={i}
                className=" rounded-lg bg-dark text-light sm-text px-4 p-2"
              >
                Entry {i + 1}
              </div>
            );
          })}
        </div>
      </div> */}
    </div>
  );
}
