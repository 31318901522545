import React from "react";

export default function Type3() {
  const service = [
    "We provide three level quality checking to meet the best outcome as per SOW (Scope of Work).",
    "Establish the project design targets and goals in a BIM management plan and set up BIM Track project setting.",
    "Establishing and maintaining design drawing standards in our detail drawings.",
    "Every team in our hierarchy consists of a Senior checker to reduce the chance of error.",
    "Develop the elements of the new design that will need to meet the requirements and run the check.",
    "We monthly grade our modelers and checkers as per their performance to ensure 100% best outcome.",
    "Develop process controls, such as clash detection, code checking, and standards checking (by using BIM Tools) to be able to deliver compliant models to the customers.",
  ];

  return (
    <div className="">
      <h1 className=" med-text font-medium">QC/QA POLICY</h1>

      <p className=" mt-8 mb-4 font-medium mini-text">
        To us, quality means strategic and operational commitment to excellence
        through being customer-centric, ethical, creative, innovative in our
        design, compliant, safe, reliable, and delivering within budget. Our
        quality objectives are established and managed by the leadership team
        and communicated through our operational procedures.
      </p>

      <ul className=" list-disc m-3">
        {service.map((s, i) => (
          <li key={i} className=" mini-text">{s}</li>
        ))}
      </ul>
    </div>
  );
}
