import React from "react";
import Header from "./comps/header";
import Founder from "./comps/founder";

import Team from "./comps/team";

export default function About() {
  return (
    <div className="sm:p-4 p-2">
      <Header />
      <Founder />
      {/* <Team /> */}
    </div>
  );
}
