const data = [
  {
    title: "Small Arms Manufacturing Plant",
    des: "Our Small Arms Manufacturing Plants are meticulously designed to enhance the production of small caliber firearms. With a strong focus on maximizing safety and operational efficiency, these plants incorporate advanced automation and modern workflow management systems. Scalable by design, they allow manufacturers to adapt to rising defence demands. Stringent security protocols and compliance with national defence regulations ensure that all production processes meet the highest standards, safeguarding both personnel and equipment.",
    // img: "/assets/images/about.jpg",
  },
  {
    title: "Small Arms and Ammunition Manufacturing Plant",
    des: "Avinya Green Constructions specializes in building highly secure Small Arms and Ammunition Manufacturing Plants, combining firearm and ammunition production within a single facility. Designed with top-tier safety and security in mind, our plants integrate robust safety protocols in accordance with CFEES and PESO regulations. Each facility is equipped with advanced systems for efficient production flow, minimizing operational risks while maintaining high output standards. These facilities ensure seamless production from raw material handling to final product assembly, supporting the critical needs of defence industries.",
    // img: "/assets/images/defence.jpg",
  },
  {
    title: "Medium and Large Caliber Ammunition Manufacturing Complex",
    des: "Our Medium and Large Caliber Ammunition Manufacturing Complexes are designed to handle the intricate and high-volume production of large munitions. These complexes feature state-of-the-art automation technologies, explosion containment systems, and optimized workflows that ensure maximum safety and efficiency. Built to accommodate the production of various ammunition types, from medium caliber rounds to large artillery shells, the facilities comply with the highest safety and defence manufacturing standards. The designs focus on safe material handling, secure storage zones, and rapid production capabilities to meet the growing demands of defence forces.",
    // img: "/assets/images/service-1.jpg",
  },
  {
    title: "Explosives Manufacturing Plant",
    des: "Avinya Green Constructions develops Explosives Manufacturing Plants with a keen emphasis on operational security, safety, and efficiency. Our designs feature specialized zones for handling and storing volatile materials, advanced air filtration systems, and explosion-resistant infrastructure to minimize risks. All facilities are fully compliant with local and international safety regulations. With a focus on optimizing productivity without compromising safety, we ensure that explosives manufacturing processes are streamlined while adhering to stringent industry protocols to protect both workers and the environment.",
    // img: "/assets/images/sub-head-2.jpg",
  },
  {
    title: "Ammunition Storage Facilities",
    des: "Our Ammunition Storage Facilities are designed to offer the highest levels of security and protection for munitions storage. Each facility includes climate-controlled environments, explosion-resistant materials, and cutting-edge security systems such as surveillance, restricted access, and automated alarms. We focus on ensuring long-term stability and safety for stored ammunition by incorporating advanced fire suppression systems and moisture control technologies. These facilities are engineered to support various ammunition types, ensuring their safety and usability over extended periods while meeting all regulatory requirements for secure storage.",
    // img: "/assets/images/sub-head-2.jpg",
  },
];

export default data;
