// import React, { useRef } from "react";
// import { motion, useScroll, useTransform } from "framer-motion";
// import { ProjectData } from "../../../comps/data";
// // import { Link } from "react-router-dom";

// export default function Projects() {
//   const container = useRef(null);

//   const { scrollYProgress } = useScroll({
//     target: container,
//     offset: ["0 0", "1 1"],
//   });
//   const x = useTransform(scrollYProgress, [0, 0.95], ["0%", "-80%"]);
//   return (
//     <div className="mt-12 md:block hidden">
//       <h1 className="big-heading mb-8 flex px-2 py-2 items-end gap-1 tracking-tight font-medium">
//         Recent Projects
//       </h1>

//       <div ref={container} className="h-[400vw] relative hidden-scroll">
//         <div className="h-screen w-full overflow-hidden sticky top-0">
//           <motion.div
//             style={{
//               x,
//             }}
//             className="w-fit h-full sm:p-8 p-4 flex gap-8 items-end"
//           >
//             {ProjectData.slice(7, 13)
//               .reverse()
//               .map((p, i) => {
//                 return (
//                   <div
//                     key={i}
//                     className="sm:w-[700px] w-[320px] max-h-[80vh] h-[80vh] rounded-2xl relative overflow-hidden md:p-8 p-4 text-light"
//                   >
//                     <div
//                       className=" absolute inset-0 -z-20"
//                       style={{
//                         borderImage:
//                           "fill 1 linear-gradient(45deg, rgba(41, 47, 54 ,0.3) 0%, rgba(247,255,247,0.2049194677871149) 100%)",
//                         backgroundImage: `url(/assets/projects/${p.img.url1})`,
//                         backgroundSize: "cover",
//                         backgroundPosition: "center",
//                         backgroundRepeat: "no-repeat",
//                       }}
//                     />
//                     <div className=" sub-heading  h-full flex items-end capitalize"> {p.title} </div>
//                   </div>
//                 );
//               })}
//           </motion.div>
//         </div>
//       </div>
//     </div>
//   );
// }

import React from "react";
import { ProjectData } from "../../../comps/data";

export default function Projects() {
  return (
    <div className="mt-12 md:block hidden">
      <h1 className="big-heading flex px-2 py-2 items-end gap-1 tracking-tight font-medium">
        Recent Projects
      </h1>

      <div className="md:columns-2 gap-8 pt-8">
        {ProjectData.slice(0, 6).map((project, i) => {
          return (
            <div key={i} className=" mb-8 p-2 border border-[#00000030] rounded-xl break-inside-avoid">
              <img
                src={`/assets/projects/${project.img.url1}`}
                alt=""
                className="w-full object-cover rounded-lg"
              />
              <h1 className="sm-heading mt-4">{project.title}</h1>
            </div>
          );
        })}
      </div>
    </div>
  );
}
