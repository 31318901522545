import React from "react";
import { motion } from "framer-motion";

export default function SubHead() {
  return (
    <motion.div className="text-light md:mt-6 mt-4 *:relative *:min-h-[25vh] grid lg:grid-cols-3 sm:grid-cols-2 gap-4 *:z-0 *:overflow-hidden *:rounded-2xl *:flex *:flex-col *:justify-center *:p-6 *:gap-1 *:bg-dark">
      <motion.div>
        <p className="big-heading">50+</p>
        <p className="sm-text pl-[0.5ch]">Completed Projects</p>
        <img
          src="/assets/images/sub-head-2.jpg"
          alt=""
          className=" object-contain w-full -z-10 absolute right-0 mix-blend-difference"
        />
      </motion.div>
      <motion.div>
        <p className="big-heading">25</p>
        <p className="sm-text pl-[0.5ch]">Years of Experience</p>
        <img
          src="https://picsum.photos/id/320/3000"
          alt=""
          className=" object-contain w-full -z-10 absolute right-0 mix-blend-color-dodge "
        />
      </motion.div>
      <motion.div>
        <p className="big-heading">20+</p>
        <p className="sm-text pl-[0.5ch]">Experienced Experts</p>
        <img
          src="/assets/images/sub-head-3.jpg"
          alt=""
          className=" object-contain w-full -z-10 absolute right-0 mix-blend-color-dodge "
        />
      </motion.div>
    </motion.div>
  );
}
