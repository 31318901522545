import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { ChevronLeft, ChevronRight } from "lucide-react";
import data from "./data";

export default function Services() {
  const [dataIndex, setDataIndex] = useState(0);

  const handlePrev = () => {
    setDataIndex((prevIndex) =>
      prevIndex > 0 ? prevIndex - 1 : data.length - 1
    );
  };

  const handleNext = () => {
    setDataIndex((prevIndex) =>
      prevIndex < data.length - 1 ? prevIndex + 1 : 0
    );
  };

  return (
    <div className="mt-12 pb-16">
      <div className="flex flex-col gap-8 max-w-4xl mx-auto relative">
        <h1 className="text-4xl font-bold text-center mb-8">
          Defence Services
        </h1>

        <div className="relative rounded-xl shadow-2xl shadow-dark/50 overflow-hidden">
          <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2 translate-y-2 flex justify-center items-center gap-4 z-20 *:text-white">
            <motion.button
              className="p-2 bg-dark/60 backdrop-blur-sm rounded-full flex items-center justify-center transition-colors"
              onClick={handlePrev}
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
              aria-label="Previous service"
            >
              <ChevronLeft size={24} />
            </motion.button>
            <motion.button
              className="p-2 bg-dark/60 backdrop-blur-sm rounded-full flex items-center justify-center transition-colors"
              onClick={handleNext}
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
              aria-label="Next service"
            >
              <ChevronRight size={24} />
            </motion.button>
          </div>
          <div className="w-full">
            <div className="aspect-w-4 aspect-h-3 relative overflow-hidden">
              <AnimatePresence initial={false} mode="wait">
                <motion.img
                  key={dataIndex}
                  src={`/assets/defense/defense_0${dataIndex + 1}.png`}
                  alt={data[dataIndex].title}
                  className="object-cover w-full h-full max-h-[500px] rounded-t-xl"
                  initial={{ x: "100%" }}
                  animate={{ x: 0 }}
                  exit={{ x: "-100%" }}
                  transition={{ type: "spring", stiffness: 300, damping: 30 }}
                />
              </AnimatePresence>
              <div className="absolute inset-x-0 bottom-0 h-16 bg-gradient-to-t from-black/50 to-transparent pointer-events-none" />
            </div>
            <div className="p-6 bg-white rounded-b-xl pb-16 overflow-hidden">
              <AnimatePresence mode="wait">
                <motion.div
                  key={dataIndex}
                  initial={{ y: 20, opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                  exit={{ y: -20, opacity: 0 }}
                  transition={{ duration: 0.3 }}
                >
                  <h2 className="text-2xl font-semibold mb-3">
                    {data[dataIndex].title}
                  </h2>
                  <p className="text-gray-600">{data[dataIndex].des}</p>
                </motion.div>
              </AnimatePresence>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
