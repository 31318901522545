import React, { useState } from "react";
import India from "./comps/india";
import US from "./comps/us";
import UK from "./comps/uk";
import Canada from "./comps/canada";

export default function Country() {
  const [active, setActive] = useState("1");

  const category = [
    {
      title: "India",
      id: "1",
    },
    {
      title: "USA",
      id: "2",
    },
    {
      title: "UK",
      id: "3",
    },
    {
      title: "Canada",
      id: "4",
    },
  ];

  const renderActiveContent = () => {
    switch (active) {
      case "1":
        return <India />;
      case "2":
        return <US />;
      case "3":
        return <UK />;
      case "4":
        return <Canada />;
      default:
        return;
    }
  };

  return (
    <div className="sm:px-4 px-2">
      <div className="med-text italic mx-auto max-w-[1024px] mb-12 flex items-end justify-center tracking-tight relative mt-20">
        Avinya Green Constructions offers innovative AEC services in India, UK,
        USA, and Canada. We excel in sustainable design, smart engineering.
        Specializing in energy-efficient buildings and renewable materials, our
        skilled team focuses on sustainability and quality. Avinya is a trusted
        partner for environmentally responsible projects across diverse markets.
        <div className="absolute bottom-[100%] md:translate-x-[75%] translate-x-[125%] -translate-y-[10px] right-[100%] big-heading lily rotate-180">
          "
        </div>
      </div>
      <div className=" md:block hidden">
        <div className="flex flex-col gap-8">
          <div className="flex items-center justify-center gap-4 w-fit mx-auto rounded-full">
            {category.map((cat, i) => {
              return (
                <div
                  key={i}
                  className={`sm-text px-4 py-2 rounded-full flex items-center justify-center cursor-pointer ${
                    active === cat.id ? "bg-dark text-white" : ""
                  }`}
                  onClick={() => {
                    setActive(cat.id);
                  }}
                >
                  {cat.title}
                </div>
              );
            })}
          </div>
          <div className=" max-w-[1024px] w-full mx-auto">
            {renderActiveContent()}
          </div>
        </div>
      </div>

      <div className="md:hidden flex flex-col gap-20">
        <div>
          <India />
        </div>

        <div>
          <US />
        </div>

        <div>
          <UK />
        </div>

        <div>
          <Canada />
        </div>
      </div>
    </div>
  );
}
