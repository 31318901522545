import React from "react";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";

export default function Header() {
  return (
    <>
      <motion.div className="mb-8 flex flex-wrap items-center content-center px-2 *:mb-1">
        <h1 className=" sm-text font-medium mr-4">We Provide Services in:</h1>

        <img
          src="/assets/country/in.png"
          alt=""
          className=" aspect-square h-[28px] mr-1 mix-blend-multiply"
        />
        <img
          src="/assets/country/Uk.png"
          alt=""
          className=" aspect-square h-[28px] mr-1 mix-blend-multiply"
        />
        <img
          src="/assets/country/USA.png"
          alt=""
          className=" aspect-square h-[28px] mr-1 mix-blend-multiply"
        />
        <img
          src="/assets/country/Can.png"
          alt=""
          className=" aspect-square h-[28px] mr-4 mix-blend-multiply"
        />

        <Link to={"/country"}>
          <h1 className=" sm-text font-medium bg-dark text-light px-4 py-2 rounded-full">
            More Details
          </h1>
        </Link>
      </motion.div>

      <motion.div
        className="overflow-hidden rounded-2xl min-h-[60vh] relative z-0 flex items-end"
        id="home-header"
      >
        <div
          className="absolute inset-0 -z-20"
          style={{
            borderImage:
              "fill 1 linear-gradient(45deg, rgba(115,158,46,0.4) 0%, rgba(247,255,247,0.2) 100%)",
          }}
        />
        <video
          className="absolute inset-0 h-full w-full object-cover"
          autoPlay
          loop
          muted
          preload="auto"
        >
          <source
            src="https://cdn.mattconstruction.com/wp-content/uploads/2022/03/20882_matt_construction_16x9_30_sec_drone_loop_v1.mp4-1080p.mp4"
            type="video/mp4"
          />
        </video>
      </motion.div>
      {/* text */}
      <motion.div className="w-full px-4 mt-12">
        <h1 className=" sm-heading  w-full -ml-[3px] font-bold text-bright">
          Your Go-To for Sustainable Green Projects
        </h1>

        <div className="mt-6 flex flex-col gap-3 *:w-full *:max-w-[1024px] font-medium">
          <p className="mini-text">
            Welcome to{" "}
            <span className=" font-extrabold text-bright inline-block uppercase">
              AVINYA GREEN Constructions
            </span>
            , your ultimate destination for comprehensive infrastructure
            solutions. From traditional infrastructure designs to cutting-edge
            augmented and virtual reality technology,{" "}
            <span className=" font-extrabold text-bright mr-[0.5ch] inline-block">
              AVINYA GREEN
            </span>
            excels in providing top-tier services that meet all your needs. Our
            passion for excellence ensures you never have to worry about your
            project's progress, and we guarantee results that exceed your
            expectations.
          </p>

          <p className=" mini-text">
            At{" "}
            <span className=" font-extrabold text-bright inline-block">
              AVINYA GREEN
            </span>
            , your satisfaction is our top priority. Our dedicated team works
            tirelessly to complete every project with the highest quality and
            precision. We believe in building long-term relationships with our
            clients by consistently delivering outstanding results. Scroll down
            to learn more about us, our products, and our services, and discover
            how we can help turn your vision into reality.
          </p>
        </div>
      </motion.div>
      {/* text */}
    </>
  );
}
