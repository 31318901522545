import React from "react";
import { ProjectData } from "../../../comps/data";

export default function MobileProject() {
  return (
    <div className=" md:hidden mt-12 sm:px-4 px-2">
      <h1 className="big-heading mb-8 flex px-2 py-2 items-end gap-1 tracking-tight font-medium">
        Recent Projects
      </h1>

      {ProjectData.slice(7, 13)
        .reverse()
        .map((p, i) => {
          return (
            <div key={i} className="mt-4 p-4">
              <img
                src={`/assets/projects/${p.img.url1}`}
                className=" w-full rounded-2xl"
                alt=""
              />
              <h1 className=" med-text text-center mt-2 capitalize font-medium mx-auto w-fit"> {p.title}</h1>
            </div>
          );
        })}
    </div>
  );
}
