import React from "react";
import Header from "./comps/header";
import SubHead from "./comps/subHead";
import About from "./comps/about";
import Projects from "./comps/projects";
import MobileProject from "./comps/mobileProject";

export default function Home() {
  return (
    <div className=" sm:p-4 p-2">
      <Header />
      <SubHead />
      <About />
      <Projects />
      <MobileProject />
    </div>
  );
}
