import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";

export default function Contact() {
  const [isLoading, setIsLoading] = useState(false);
  const [formStatus, setFormStatus] = useState("");

  useEffect(() => {
    if (formStatus) {
      const timer = setTimeout(() => {
        setFormStatus("");
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [formStatus]);

  const onSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    setFormStatus("");

    const form = event.target;
    const formData = new FormData(form);

    formData.append("access_key", "74c3c3d1-2343-435f-a072-3c4096c31648");

    const object = Object.fromEntries(formData);
    const json = JSON.stringify(object);

    try {
      const res = await fetch("https://api.web3forms.com/submit", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: json,
      }).then((res) => res.json());

      if (res.success) {
        console.log("Success", res);
        setFormStatus("success");
        form.reset(); // Reset all form fields
      } else {
        setFormStatus("error");
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      setFormStatus("error");
    } finally {
      setIsLoading(false);
    }
  };

  const alertVariants = {
    hidden: { opacity: 0, x: 50 },
    visible: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: 50 },
  };

  return (
    <div className="sm:px-4 px-2 flex flex-col items-center justify-center min-h-[90vh]">
      <h1 className="big-heading flex -ml-[3px] items-end tracking-tight font-medium text-center">
        Get in Touch
      </h1>

      <p className="sm-text max-w-[480px] mt-8 mb-2 text-center">
        You may contact us for any queries. We will be happy to serve you.
      </p>

      <div className="max-w-[520px] w-full relative">
        <form onSubmit={onSubmit}>
          <div className="mb-5">
            <label
              htmlFor="name"
              className="mb-3 block text-base font-medium text-bright"
            >
              Full Name
            </label>
            <input
              type="text"
              name="name"
              id="name"
              placeholder="Full Name"
              className="w-full rounded-md border border-light bg-light py-3 px-6 text-base font-medium text-dark outline-none focus:border-dark focus:shadow-md"
              required
            />
          </div>
          <div className="mb-5">
            <label
              htmlFor="email"
              className="mb-3 block text-base font-medium text-bright"
            >
              Email Address
            </label>
            <input
              type="email"
              name="email"
              id="email"
              placeholder="example@domain.com"
              className="w-full rounded-md border border-light bg-light py-3 px-6 text-base font-medium text-dark outline-none focus:border-dark focus:shadow-md"
              required
            />
          </div>
          <div className="mb-5">
            <label
              htmlFor="from_name"
              className="mb-3 block text-base font-medium text-bright"
            >
              Subject
            </label>
            <input
              type="text"
              name="from_name"
              placeholder="Enter your subject"
              className="w-full rounded-md border border-light bg-light py-3 px-6 text-base font-medium text-dark outline-none focus:border-dark focus:shadow-md"
              required
            />
          </div>
          <div className="mb-5">
            <label
              htmlFor="message"
              className="mb-3 block text-base font-medium text-bright"
            >
              Message
            </label>
            <textarea
              rows={4}
              name="message"
              id="message"
              placeholder="Type your message"
              className="w-full resize-none rounded-md border border-light bg-light py-3 px-6 text-base font-medium text-dark outline-none focus:border-dark focus:shadow-md"
              required
            />
          </div>
          <div>
            <button
              className="hover:shadow-form rounded-md bg-bright py-3 px-8 text-base font-semibold text-white outline-none flex items-center justify-center w-32"
              disabled={isLoading}
            >
              {isLoading ? (
                <span className="flex items-center">
                  Sending
                  <span className="ml-2 inline-block w-4 h-4 border-2 border-white border-t-transparent rounded-full animate-spin"></span>
                </span>
              ) : (
                "Submit"
              )}
            </button>
          </div>
        </form>
      </div>

      <AnimatePresence>
        {formStatus && (
          <motion.div
            className="fixed top-4 right-4 max-w-[480px] w-fit overflow-hidden shadow-lg z-50"
            initial="hidden"
            animate="visible"
            exit="exit"
            variants={alertVariants}
            transition={{ type: "spring", stiffness: 300, damping: 30 }}
          >
            {formStatus === "success" ? (
              <div className="bg-green-100 border-l-4 border-green-500 text-green-700 p-4">
                <p className="font-bold">Success</p>
                <p>Your message has been sent.&#10003;</p>
              </div>
            ) : (
              <div className="bg-red-100 border-l-4 border-red-500 text-red-700 p-4">
                <p className="font-bold">Error</p>
                <p>Please try again.&#10007;</p>
              </div>
            )}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}
