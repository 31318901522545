import React from "react";
import { Link } from "react-router-dom";

export default function About() {
  return (
    <div className="mt-12">
      <div className=" flex md:flex-row flex-col items-start justify-between mb-4">
        <h1 className="big-heading flex px-2 py-2 items-end gap-1 tracking-tight font-medium">
          About Us
        </h1>

        <p className="max-w-[420px] p-2 mini-text">
          <span className=" inline-block mr-[0.5ch] md:ml-[10ch] text-bright font-medium">
            At AVINYA GREEN,
          </span>
          we are committed to revolutionizing infrastructure with innovative
          solutions, ensuring sustainability, efficiency, and excellence. Our
          goal is to exceed client expectations and deliver world-class results
          in every project we undertake.
        </p>
      </div>

      <div className="*:min-h-[400px] *:rounded-2xl grid gap-2 md:grid-cols-2">
        <div
          className=" row-span-2"
          style={{
            backgroundImage: "url('https://picsum.photos/id/43/3000')",
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
          }}
        ></div>
        <div
          className=" md:block hidden "
          style={{
            backgroundImage: "url('https://picsum.photos/id/84/3000/1200')",
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
          }}
        ></div>
        <div className="flex flex-col justify-center gap-4 p-8 bg-dark text-light">
          <p className="sm-text">
            <span
              className=" inline-block sm-heading text-bright mr-[0.25ch]"
              style={{ lineHeight: 1.1 }}
            >
              Avinya Green Constructions,
            </span>
            founded by a dynamic IIM-A and IIT-D Alumnus, Structural Engineer
            and ex-Armed Forces officer, with a vibrant portfolio comprising
            commercial, residential and mixed use projects spread philosophy of
            innovation, sustainability, excellence in the real estate industry.
            across key cities in India. We breathe the and Our development model
            combines the legacy of excellence, trust, ownership and commitment
            to cutting-edge design and technology, rolled into one solid
            delivery.
          </p>
          <Link to={"/about"}>
            <div className="w-fit px-4 py-2 sm-text rounded-full text-bright bg-light hover:text-dark hover:bg">
              Learn More
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
}
