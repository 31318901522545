import React from "react";

export default function India() {
  const codes = [
    {
      name: "National Building Code of India (NBC)",
      details: [
        "Comprehensive building Code providing guidelines for regulating building construction activities across India.",
        "Covers aspects like structural design, fire safety, building materials, and construction practices.",
      ],
    },
    {
      name: "Bureau of Indian Standards (BIS) Codes",
      details: [
        "IS 456: Code of Practice for Plain and Reinforced Concrete",
        "IS 800: Code of Practice for General Construction in Steel",
        "IS 875: Code of Practice for Design Loads for Buildings and Structures",
      ],
    },
    {
      name: "Energy Conservation Building Code (ECBC)",
      details: [
        "Sets minimum energy performance standards for buildings in India.",
        "Aims to reduce energy consumption in buildings without affecting comfort and productivity.",
      ],
    },
    {
      name: "Development Control Regulations (DCR)",
      details: [
        "Local regulations that govern building construction in specific cities or regions.",
        "Cover aspects like Floor Space Index (FSI), setbacks, and building height restrictions.",
      ],
    },
    {
      name: "Environmental Impact Assessment (EIA) Notification",
      details: [
        "Mandates environmental clearance for large building projects.",
        "Aims to minimize the environmental impact of construction activities.",
      ],
    },
    {
      name: "Real Estate (Regulation and Development) Act, 2016 (RERA)",
      details: [
        "Regulates the real estate sector and ensures transparency in real estate transactions.",
        "Establishes state-level regulatory authorities to oversee real estate projects.",
      ],
    },
    // {
    //   name: "Coastal Regulation Zone (CRZ) Notifications",
    //   details: [
    //     "Regulates development activities in coastal areas of India.",
    //     "Aims to protect coastal ecosystems and livelihoods of coastal communities.",
    //   ],
    // },
  ];

  return (
    <div className="">
      <div className="mb-6 -ml-1 sm-heading font-bold flex flex-wrap gap-2 content-center items-center">
        <h1>Service Provided</h1>
        <img
          src="/assets/country/in.png"
          alt=""
          className=" lg:h-[40px] md:h-[32px] h-[28px] mix-blend-multiply"
        />
      </div>

      <div className="mb-8 flex flex-wrap gap-2 text-light sm-text font-medium *:w-fit *:px-4 *:py-3 *:rounded-full *:bg-dark">
        <div>Architecture</div>
        <div>Structural Design</div>
        <div>Construction</div>
        <div> Structural Health Monitoring</div>
        <div>Structural Audits</div>
      </div>

      <h1 className="med-text font-bold mb-4">
        Indian Codal Provisions for Building Designs
      </h1>
      <ol className="space-y-6 p-2">
        {codes.map((code, index) => (
          <li key={index} className="rounded-lg">
            <h2 className="sm-text font-semibold mb-2">
              {index + 1}. {code.name}
            </h2>
            <ul className="list-disc pl-6 space-y-1">
              {Array.isArray(code.details) ? (
                code.details.map((detail, detailIndex) => (
                  <li key={detailIndex} className="mini-text">
                    {detail}
                  </li>
                ))
              ) : (
                <li className="mini-text">{code.details}</li>
              )}
            </ul>
          </li>
        ))}
      </ol>
      <p className="mt-4 mini-text italic">
        These codes and standards are widely adopted and enforced across India.
        They aim to ensure public safety, accessibility, energy efficiency, and
        environmental sustainability in building design and construction.
      </p>
    </div>
  );
}
