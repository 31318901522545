import React from "react";

export default function Canada() {
  const codes = [
    {
      name: "National Building Code (NBC)",
      details:
        "Concerned with health, safety, accessibility and the protection of buildings from fire or structural damage.",
    },
    {
      name: "National Fire Code (NFC)",
      details:
        "Provides minimum fire safety requirements for buildings, structures, and areas where hazardous materials are used.",
    },
    {
      name: "National Plumbing Code (NPC)",
      details:
        "Sets standards for the design and installation of plumbing systems in buildings.",
    },
    {
      name: "Canadian Standards Association (CSA)",
      details:
        "Publishes model codes that address electrical, gas and elevator systems.",
    },
    {
      name: "Prescriptive Design",
      details:
        "Housing and small buildings can be built without a full structural design using prescriptive requirements found in Part 9 of the NBC.",
    },
    {
      name: "Seismic Design Provisions in the National Building Code of Canada",
      details:
        "This code presents the significant changes to the approach taken in determining seismic hazard, the seismic hazard maps and describes the evolution of the seismic design provisions of the National Building Code of Canada.",
    },
  ];

  return (
    <div className="">
      <div className="mb-6 -ml-1 sm-heading font-bold flex flex-wrap gap-2 content-center items-center">
        <h1>Service Provided</h1>
        <img src="/assets/country/Can.png" alt="" className=" lg:h-[40px] md:h-[32px] h-[28px] mix-blend-multiply" />
      </div>

      <div className="mb-8 flex flex-wrap gap-2 text-light sm-text font-medium *:w-fit *:px-4 *:py-3 *:rounded-full *:bg-dark">
        <div>Architecture</div>
        <div>Structural Design</div>
      </div>

      <h1 className=" med-text font-bold mb-6">
        Canadian Codal Provisions for Building Design
      </h1>
      <ol className="space-y-6 p-2">
        {codes.map((code, index) => (
          <li key={index} className="rounded-lg">
            <h2 className="sm-text font-semibold mb-2">
              {index + 1}. {code.name}
            </h2>
            <ul className="list-disc pl-6 space-y-1">
              {Array.isArray(code.details) ? (
                code.details.map((detail, detailIndex) => (
                  <li key={detailIndex} className="mini-text">
                    {detail}
                  </li>
                ))
              ) : (
                <li className="mini-text">{code.details}</li>
              )}
            </ul>
          </li>
        ))}
      </ol>
      <p className="mt-6 mini-text text-gray-600 italic">
        These codes and standards are widely adopted and enforced across Canada,
        aiming to ensure public safety, accessibility, and structural integrity
        in building design and construction.
      </p>
    </div>
  );
}
