import React from "react";

import { ProjectData } from "../../comps/data";

export default function Projects() {
  return (
    <div className="relative sm:px-4 px-2 h-fit">
      <h1 className=" big-heading font-medium mt-12"> Our Work </h1>
      <div className=" h-[2px] bg-dark w-full my-4" />
      <div className="md:columns-2 gap-8">
        {ProjectData.map((project, i) => {
          return (
            <div
              key={i}
              className="mb-8 w-full p-4 bg-dark rounded-xl flex flex-col gap-4 break-inside-avoid"
            >
              <img
                src={`/assets/projects/${project.img.url1}`}
                alt=""
                className="w-full object-cover rounded-lg"
              />
              <div className=" text-white px-0.5">
                <h1 className="sm-heading font-medium -mx-0.5 capitalize">
                  {" "}
                  {project.title}{" "}
                </h1>
                <p className="sm-text mt-4 capitalize">
                  {project.location}, ({project.area})
                </p>
                <p className="sm-text mt-1 flex flex-wrap gap-2">
                  <span> Service Provided: </span>
                  <span>{project.service}</span>
                </p>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
