import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";
import { Menu, X, ArrowBigRight } from "lucide-react";
import Headroom from "react-headroom";

const navLinks = [
  { heading: "Home", link: "/" },
  { heading: "About", link: "/about" },
  { heading: "Projects", link: "/projects" },
  { heading: "Defence & Aerospace", link: "/defence" },
  { heading: "Services", link: "/services" },
  { heading: "Contact", link: "/contact" },
];

export default function Navbar() {
  const [isOpen, setIsOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const location = useLocation();

  const isActive = (link) => location.pathname === link;

  useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY > 20);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <Headroom>
      <nav
        className={`w-full z-50 transition-all duration-300 ${
          scrolled ? "bg-bg shadow-md" : "bg-transparent"
        }`}
      >
        <div className="px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between items-center h-16">
            <Link to="/" className="flex items-center space-x-2">
              <img
                src="/assets/images/logo.webp"
                alt="Logo"
                className="h-12 w-auto"
              />
              <span className="text-lg font-semibold flex flex-col text-bright">
                <span>AVINYA GREEN </span>
                <span className="-mt-2">CONSTRUCTIONS</span>
              </span>
            </Link>

            <div className="hidden lg:flex items-center space-x-2">
              {navLinks.map((link) => (
                <NavLink key={link.heading} {...link} isActive={isActive} />
              ))}
            </div>

            <div className="lg:hidden">
              <button
                onClick={() => setIsOpen(!isOpen)}
                className="text-gray-500 hover:text-gray-600"
              >
                {isOpen ? <X size={24} /> : <Menu size={24} />}
              </button>
            </div>
          </div>
        </div>

        <AnimatePresence>
          {isOpen && (
            <motion.div
              initial={{ opacity: 0, height: 0 }}
              animate={{ opacity: 1, height: "auto" }}
              exit={{ opacity: 0, height: 0 }}
              transition={{ duration: 0.3 }}
              className="lg:hidden fixed left-0 right-0 top-16 bg-bg shadow-lg overflow-hidden"
            >
              <div className="px-2 py-3 space-y-1 sm:px-3">
                {navLinks.map((link) => (
                  <MobileNavLink
                    key={link.heading}
                    {...link}
                    isActive={isActive}
                    setIsOpen={setIsOpen}
                  />
                ))}
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </nav>
    </Headroom>
  );
}

const NavLink = ({ heading, link, isActive }) => (
  <Link
    to={link}
    className={`px-3 py-2 rounded-md text-md font-medium ${
      isActive(link)
        ? "bg-bright text-white"
        : "text-bright hover:bg-gray-700 hover:text-white"
    } transition-all duration-200`}
  >
    {heading}
  </Link>
);

const MobileNavLink = ({ heading, link, isActive, setIsOpen }) => (
  <Link
    to={link}
    className={`px-3 py-2 rounded-md text-base font-medium flex items-center gap-2  ${
      isActive(link)
        ? "bg-bright text-white"
        : "text-bright hover:bg-gray-700 hover:text-white"
    } transition-all duration-200`}
    onClick={() => setIsOpen(false)}
  >
    <ArrowBigRight size={16} />
    {heading}
  </Link>
);
