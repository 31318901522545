import React from "react";
import { Link } from "react-router-dom";

export default function Header() {
  return (
    <div className="flex flex-col gap-4 mt-12">
      <div className="flex lg:flex-row flex-col justify-between gap-4 *:lg:max-w-[640px]">
        <h1 className=" big-heading flex items-end">Defence & Aerospace</h1>
        <p className=" sm-text lg:text-right flex flex-col lg:justify-end lg:items-end">
          Avinya Green Constructions designs high-security, efficient defence
          and aerospace facilities, ensuring compliance with safety regulations
          and industry standards for optimal performance.
          <Link to={"/contact"} className=" mt-4">
            <span className=" flex w-fit p-2 rounded-full bg-bright text-white">
              {" "}
              Contact Us{" "}
            </span>
          </Link>
        </p>
      </div>
      <div className=" h-[2px] bg-dark w-full my-2" />
      <div className="">
        <img
          src="/assets/images/defense.jpg"
          alt=""
          className=" object-cover object-center md:max-h-[600px] max-h-[300px] w-full rounded-xl"
        />
      </div>
    </div>
  );
}
