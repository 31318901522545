import React from "react";

export default function US() {
  const codes = [
    {
      name: "International Building Code (IBC)",
      details: [
        "Covers minimum design and construction requirements for new buildings.",
        "Addresses fire safety, structural integrity, and accessibility.",
      ],
    },
    {
      name: "International Residential Code (IRC)",
      details: [
        "Applies to one- and two-family dwellings.",
        "Covers design and construction requirements for residential buildings.",
      ],
    },
    {
      name: "Americans with Disabilities Act (ADA)",
      details: [
        "Requires accessible design for buildings, including ramps, elevators, and restrooms.",
      ],
    },
    {
      name: "National Fire Protection Association (NFPA) codes",
      details: [
        "NFPA 13: Fire sprinkler systems",
        "NFPA 72: Fire alarm systems",
      ],
    },
    {
      name: "International Energy Conservation Code (IECC)",
      details: ["Sets minimum energy efficiency requirements for buildings."],
    },
    {
      name: "ASCE 7: Minimum Design Loads for Buildings and Other Structures",
      details: [
        "Provides requirements for structural design loads, including wind and seismic loads.",
      ],
    },
    {
      name: "ACI 318: Building Code Requirements for Structural Concrete",
      details: [
        "Covers design and construction requirements for concrete structures.",
      ],
    },
    {
      name: "ICC/ANSI A117.1: Accessible and Usable Buildings and Facilities",
      details: ["Provides guidelines for accessible design."],
    },
  ];

  return (
    <div className="">
      <div className="mb-6 -ml-1 sm-heading font-bold flex flex-wrap gap-2 content-center items-center">
        <h1>Service Provided</h1>
        <img
          src="/assets/country/USA.png"
          alt=""
          className=" lg:h-[40px] md:h-[32px] h-[28px] mix-blend-multiply"
        />
      </div>

      <div className="mb-8 flex flex-wrap gap-2 text-light sm-text font-medium *:w-fit *:px-4 *:py-3 *:rounded-full *:bg-dark">
        <div>Architecture</div>
        <div>Structural Design</div>
      </div>

      <h1 className="med-text font-bold mb-4">
        American Codal Provisions for Building Designs
      </h1>
      <ol className="space-y-6 p-2">
        {codes.map((code, index) => (
          <li key={index} className="rounded-lg">
            <h2 className="sm-text font-semibold mb-2">
              {index + 1}. {code.name}
            </h2>
            <ul className="list-disc pl-6 space-y-1">
              {Array.isArray(code.details) ? (
                code.details.map((detail, detailIndex) => (
                  <li key={detailIndex} className="mini-text">
                    {detail}
                  </li>
                ))
              ) : (
                <li className="mini-text">{code.details}</li>
              )}
            </ul>
          </li>
        ))}
      </ol>
      <p className="mt-4 text-sm italic">
        These codes and standards are widely adopted and enforced by local
        jurisdictions in the United States. They aim to ensure public safety,
        accessibility, and energy efficiency in building design and
        construction.
      </p>
    </div>
  );
}
