import React from "react";
import { useParams, Link } from "react-router-dom";
import { ProjectData } from "../../comps/data";

export default function ProjectDetail() {
  const { slug } = useParams();
  const project = ProjectData.find((p) => p.slug === slug);

  if (!project) {
    return (
      <div className="sm:px-4 px-2">
        <Link to="/projects">
          <p className="mb-4 sm-heading font-bold px-2">Back to projects</p>
        </Link>
        <div className=" px-2 big-heading font-extrabold">
          Project not found !!!!
        </div>
      </div>
    );
  }

  return (
    <div className="sm:px-4 px-2">
      <Link to="/projects">
        <p className="mb-4 sm-heading font-bold px-2">Back to projects</p>
      </Link>

      <div className="h-[75vh] rounded-2xl overflow-hidden">
        <img
          src={`/assets/projects/${project.img.url1}`}
          alt={project.title}
          className="object-cover w-full h-full"
        />
      </div>

      <h1 className="mt-4 -ml-[3px] px-0.5 sub-heading font-medium">
        {project.title}
      </h1>
      <p className="px-1 sm-text mt-4">{project.detailedDescription}</p>

      {/* <div className="mt-8 flex flex-wrap gap-4 justify-center content-center">
        {Object.entries(project.img).map(([key, url]) => (
          <div key={key} className="flex">
            <img
              src={`/assets/projects/${url}`}
              alt={`${project.title} - ${key}`}
              className=" h-[500px] aspect-square rounded-2xl"
            />
          </div>
        ))}
      </div> */}
    </div>
  );
}
