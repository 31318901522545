import React from "react";

export default function UK() {
  const codes = [
    {
      name: "Eurocode (EC) series",
      details: [
        "EC 0: Basis of structural design",
        "EC 1: Actions on structures (loads)",
        "EC 2: Design of concrete structures",
        "EC 3: Design of steel structures",
        "EC 4: Design of composite steel and concrete structures",
        "EC 5: Design of timber structures",
        "EC 6: Design of masonry structures",
        "EC 7: Geotechnical design",
        "EC 8: Design of structures for earthquake resistance",
      ],
    },
    {
      name: "European Union (EU) Directives and Regulations",
      details: [
        "EU Directive 2010/31/EU: Energy performance of buildings",
        "EU Regulation 305/2011: Construction products",
        "EU Directive 2014/35/EU: Low voltage electrical equipment",
      ],
    },
    {
      name: "European Standards (EN) series",
      details: [
        "EN 1990: Basis of structural design",
        "EN 1991: Actions on structures (loads)",
        "EN 1992: Design of concrete structures",
        "EN 1993: Design of steel structures",
        "EN 1994: Design of composite steel and concrete structures",
        "EN 1995: Design of timber structures",
        "EN 1996: Design of masonry structures",
        "EN 1997: Geotechnical design",
        "EN 1998: Design of structures for earthquake resistance",
      ],
    },
    {
      name: "National Annexes",
      details: [
        "Each EU member state has its own National Annex, which provides additional requirements and specifications for building design.",
      ],
    },
    {
      name: "Other European codes and standards",
      details: [
        "CEN/TR 16792-1:2014: Fire safety in buildings",
        "CEN/TR 16792-2:2014: Fire safety in buildings - Fire safety strategy",
      ],
    },
  ];

  return (
    <div className="">
      <div className="mb-6 -ml-1 sm-heading font-bold flex flex-wrap gap-2 content-center items-center">
        <h1>Service Provided</h1>
        <img
          src="/assets/country/Uk.png"
          alt=""
          className=" lg:h-[40px] md:h-[32px] h-[28px] mix-blend-multiply"
        />
      </div>

      <div className="mb-8 flex flex-wrap gap-2 text-light sm-text font-medium *:w-fit *:px-4 *:py-3 *:rounded-full *:bg-dark">
        <div>Architecture</div>
        <div>Structural Design</div>
      </div>

      <h1 className="text-2xl font-bold mb-6">
        European Codal Provisions for Building Design
      </h1>
      <ol className="space-y-6 p-2">
        {codes.map((code, index) => (
          <li key={index} className="rounded-lg">
            <h2 className="sm-text font-semibold mb-2">
              {index + 1}. {code.name}
            </h2>
            <ul className="list-disc pl-6 space-y-1">
              {Array.isArray(code.details) ? (
                code.details.map((detail, detailIndex) => (
                  <li key={detailIndex} className="mini-text">
                    {detail}
                  </li>
                ))
              ) : (
                <li className="mini-text">{code.details}</li>
              )}
            </ul>
          </li>
        ))}
      </ol>
      <p className="mt-6 mini-text text-gray-600 italic">
        These codes and standards are widely adopted and enforced by European
        Union member states, aiming to ensure public safety, accessibility,
        energy efficiency, and environmental sustainability in building design
        and construction.
      </p>
    </div>
  );
}
