import React from "react";
import Header from "./comps/header";
import Services from "./comps/services";

export default function Defense() {
  return (
    <div className="sm:p-4 p-2">
      <Header />
      <Services />
    </div>
  );
}
