import React from "react";

export default function Type2() {
  const service = [
    "BIM Modelling (A/S/MEPF)",
    "BIM Coordination (A/S/MEPF)",
    "BIM Family & Content Development",
    "Walk Through Presentation",
    "Schedules & Quantity Takeoff",
    "Drawing Generation (SD/CD/DD/GFC/As-Built/SEM)",
    "4D Construction Sequencing",
    "5D Cost Simulation",
    "Scan To BIM",
  ];

  return (
    <div className="">
      <ul className="flex flex-wrap content-center md:justify-center">
        {service.map((s, i) => {
          return (
            <li key={i} className="sm-text font-medium relative md:pr-16 pb-8 flex flex-col gap-2 md:w-fit w-full">
              {i+1}) {s}
              <div className="w-full h-[1px] bg-dark" />
            </li>
          );
        })}
      </ul>
    </div>
  );
}
