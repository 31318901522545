import React from "react";
import { Link } from "react-router-dom";

// const scrollToTop = () => {
//   window.scrollTo({
//     top: 0,
//     behavior: "smooth",
//   });
// };

export default function Footer() {
  const navLinks = [
    { heading: "Home", link: "/" },
    { heading: "About", link: "/about" },
    { heading: "Projects", link: "/projects" },
    { heading: "Defence", link: "/defence" },
    { heading: "Services", link: "/services" },
    { heading: "Contact", link: "/contact" },
  ];
  return (
    <div className="sm:p-4 p-2 mt-4">
      <div className="bg-bright md:px-8 px-4 py-2 rounded-2xl text-light flex flex-col">
        <div className=" flex md:flex-row flex-col gap-4 justify-between py-8">
          <h1 className=" sub-heading inline-block *:inline-block w-full max-w-[900px]">
            <span className="font-semibold">Discuss</span> Your Idea {""}
            <span className="font-semibold">With Us</span>
            <br />
            <Link to={"mailto:info@avinyagreen.com"} className="">
              <span className="inline-block w-fit med-text px-4 py-2 bg-light text-bright ml-[0.25ch] -translate-y-2 rounded-full">
                Let's Talk
              </span>
              <span className="inline-block rounded-full bg-light p-2 -ml-1 translate-y-2.5">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width={40}
                  height={40}
                  color={"#739E2E"}
                  fill={"none"}
                >
                  <path
                    d="M17 7L6 18"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                  />
                  <path
                    d="M11 6.13151C11 6.13151 16.6335 5.65662 17.4885 6.51153C18.3434 7.36645 17.8684 13 17.8684 13"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </span>
            </Link>
          </h1>

          <div className="flex flex-col gap-1 justify-center md:text-right">
            <p>info@avinyagreen.com</p>
            <p>+91 9013933333</p>
            <Link
              to={
                "https://www.linkedin.com/company/avinya-green-constructions/"
              }
              className="flex justify-end md:w-full w-fit"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width={24}
                height={24}
                color={"#F7FFF7"}
                fill={"none"}
              >
                <path
                  d="M4.5 9.5H4C3.05719 9.5 2.58579 9.5 2.29289 9.79289C2 10.0858 2 10.5572 2 11.5V20C2 20.9428 2 21.4142 2.29289 21.7071C2.58579 22 3.05719 22 4 22H4.5C5.44281 22 5.91421 22 6.20711 21.7071C6.5 21.4142 6.5 20.9428 6.5 20V11.5C6.5 10.5572 6.5 10.0858 6.20711 9.79289C5.91421 9.5 5.44281 9.5 4.5 9.5Z"
                  stroke="currentColor"
                  strokeWidth="1.5"
                />
                <path
                  d="M6.5 4.25C6.5 5.49264 5.49264 6.5 4.25 6.5C3.00736 6.5 2 5.49264 2 4.25C2 3.00736 3.00736 2 4.25 2C5.49264 2 6.5 3.00736 6.5 4.25Z"
                  stroke="currentColor"
                  strokeWidth="1.5"
                />
                <path
                  d="M12.326 9.5H11.5C10.5572 9.5 10.0858 9.5 9.79289 9.79289C9.5 10.0858 9.5 10.5572 9.5 11.5V20C9.5 20.9428 9.5 21.4142 9.79289 21.7071C10.0858 22 10.5572 22 11.5 22H12C12.9428 22 13.4142 22 13.7071 21.7071C14 21.4142 14 20.9428 14 20L14.0001 16.5001C14.0001 14.8433 14.5281 13.5001 16.0879 13.5001C16.8677 13.5001 17.5 14.1717 17.5 15.0001V19.5001C17.5 20.4429 17.5 20.9143 17.7929 21.2072C18.0857 21.5001 18.5572 21.5001 19.5 21.5001H19.9987C20.9413 21.5001 21.4126 21.5001 21.7055 21.2073C21.9984 20.9145 21.9985 20.4432 21.9987 19.5006L22.0001 14.0002C22.0001 11.515 19.6364 9.50024 17.2968 9.50024C15.9649 9.50024 14.7767 10.1531 14.0001 11.174C14 10.5439 14 10.2289 13.8632 9.995C13.7765 9.84686 13.6531 9.72353 13.505 9.63687C13.2711 9.5 12.9561 9.5 12.326 9.5Z"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinejoin="round"
                />
              </svg>
            </Link>
          </div>
        </div>

        <div className="h-[1px] w-full bg-light"></div>
        <div className=" py-8 flex md:flex-row flex-col justify-between items-center  gap-2">
          <Link
            to="/"
            //  onClick={scrollToTop}
          >
            <div className="flex gap-2 items-center mini-text font-medium">
              {" "}
              <img
                src="/assets/images/logo.webp"
                alt=""
                className="h-[40px]"
              />{" "}
              <span>
                AVINYA GREEN <br />
                CONSTRUCTIONS
              </span>{" "}
            </div>
          </Link>
          <div className="flex gap-2 items-center mini-text">
            {navLinks.map((link, i) => {
              return (
                <Link
                  key={i}
                  to={link.link}
                  // onClick={scrollToTop}
                >
                  <div> {link.heading} </div>
                </Link>
              );
            })}
          </div>

          <div className="mini-text">©2023 by Avinya Green Constructions.</div>
        </div>
      </div>
    </div>
  );
}
