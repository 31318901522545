export const MemberData = [
  {
    name: "Col Rohit Gogna",
    role: "Founder & MD",
    description:
      "Experienced project manager with a background in civil engineering and construction.",
    linkedin: "https://www.linkedin.com/in/jettiefeeney",
    img: "Rohit.webp",
  },
  {
    name: "Ar. Aarti Dhiman",
    role: "Business Head & Partner",
    description:
      "Senior software developer with expertise in full-stack development and cloud computing.",
    linkedin: "https://www.linkedin.com/in/gracieschmidt",
    img: "Arti.webp",
  },
  {
    name: "Er. Abhinav Chamoli",
    role: "Business Head & Partner",
    description:
      "Creative marketing specialist with a passion for digital media and brand strategy.",
    linkedin: "https://www.linkedin.com/in/aidenjohnson",
    img: "Abhinav.webp",
  },
  {
    name: "Dr. Dattar Singh Aulakh",
    role: "Business Head & Partner",
    description:
      "Data analyst skilled in statistical analysis and data visualization techniques.",
    linkedin: "https://www.linkedin.com/in/sophiabrown",
    img: "Dattar.webp",
  },
];

export const ProjectData = [
  {
    title: "Modern Urban Residence",
    area: "16000 sqft",
    location: "Lucknow, UP",
    service: " Architecture, Structure and MEPF ",
    detailedDescription:
      "Located in the heart of the city, this modern urban residence combines sleek design with functionality. Featuring open floor plans, floor-to-ceiling windows, and state-of-the-art amenities, it offers a luxurious living experience in an urban setting.",
    briefDescription:
      "A luxurious modern residence in the city center with open floor plans and state-of-the-art amenities.",
    img: {
      url1: "Project-1.jpeg",
      url2: "Project-1.jpeg",
    },
    slug: "modern-urban-residence",
  },
  {
    title: "Residential Project",
    area: "6000 sqft",
    location: "Dehradun, Uttrakhand ",
    service: " Architecture, Structure and MEPF",
    detailedDescription:
      "This residence offers a tranquil escape by the sea, with panoramic views of the ocean. The design emphasizes natural light and airy spaces, creating a seamless connection between indoor and outdoor living. It's the perfect retreat for those looking to unwind and enjoy the coastal lifestyle.",
    briefDescription:
      "A tranquil seaside residence with panoramic ocean views and a seamless indoor-outdoor living experience.",
    img: {
      url1: "Project-2.jpeg",
      url2: "Project-1.jpeg",
    },
    slug: "seaside-escape",
  },
  {
    title: "Hospital Project",
    area: "25000 sqft",
    location: "Bareilly, UP ",
    service: " Architecture  Structure ",
    detailedDescription:
      "Nestled in the mountains, this retreat is designed for those who seek solace in nature. With rustic yet modern architecture, it features expansive windows, cozy fireplaces, and outdoor decks that offer stunning views of the surrounding landscape. It's an ideal getaway for nature lovers.",
    briefDescription:
      "A mountain retreat with rustic-modern architecture, offering stunning views and cozy living spaces.",
    img: {
      url1: "Project-3.jpeg",
      url2: "Project-1.jpeg",
    },
    slug: "mountain-retreat",
  },
  {
    title: "Luxurious Duplex",
    area: "3500 sqft",
    location: "Dehradun, Uttrakhand ",
    service: " Architecture and Structure",
    detailedDescription:
      "This villa in the countryside combines traditional charm with modern comforts. Surrounded by rolling hills and lush greenery, it features spacious interiors, a private garden, and a pool. It's perfect for those looking to experience the serene and picturesque countryside life.",
    briefDescription:
      "A charming countryside villa with modern comforts, featuring a private garden and pool.",
    img: {
      url1: "Project-4.jpeg",
      url2: "Project-5.jpeg",
    },
    slug: "countryside-villa",
  },
  // {
  //   title: "Eco-Friendly Residence",
  //   detailedDescription:
  //     "This eco-friendly residence is designed with sustainability in mind. It features energy-efficient systems, solar panels, and sustainable materials. The design promotes a healthy and environmentally-conscious lifestyle, making it an excellent choice for those who prioritize green living.",
  //   briefDescription:
  //     "An eco-friendly residence with energy-efficient systems and sustainable materials, promoting green living.",
  //   img: {
  //     url1: "Project-5.jpeg",
  //     url2: "Project-1.jpeg",
  //   },
  //   slug: "eco-friendly-residence",
  // },

  {
    title: "Luxurious Commercial Project ",
    area: "45000 sqft",
    location: "Noida, Delhi-NCR ",
    service: "BIM(Architecture, Structure and MEPF)",
    detailedDescription:
      "This eco-friendly residence is designed with sustainability in mind. It features energy-efficient systems, solar panels, and sustainable materials. The design promotes a healthy and environmentally-conscious lifestyle, making it an excellent choice for those who prioritize green living.",
    briefDescription:
      "An eco-friendly residence with energy-efficient systems and sustainable materials, promoting green living.",
    img: {
      url1: "Project-6.jpeg",
      url2: "Project-1.jpeg",
    },
    slug: "eco-friendly-residence",
  },
  {
    title: "Luxurious Commercial Project ",
    area: "23400 sqft",
    location: "Noida, Delhi-NCR ",
    service: "BIM (Architecture, Structure and MEPF)",
    detailedDescription:
      "This eco-friendly residence is designed with sustainability in mind. It features energy-efficient systems, solar panels, and sustainable materials. The design promotes a healthy and environmentally-conscious lifestyle, making it an excellent choice for those who prioritize green living.",
    briefDescription:
      "An eco-friendly residence with energy-efficient systems and sustainable materials, promoting green living.",
    img: {
      url1: "Project-7.jpeg",
      url2: "Project-1.jpeg",
    },
    slug: "eco-friendly-residence",
  },
  {
    title: "Luxurious Hospital Project ",
    area: "100000 sqft",
    location: "Gurugram, Harayana ",
    service: "BIM (Structure)",
    detailedDescription:
      "This eco-friendly residence is designed with sustainability in mind. It features energy-efficient systems, solar panels, and sustainable materials. The design promotes a healthy and environmentally-conscious lifestyle, making it an excellent choice for those who prioritize green living.",
    briefDescription:
      "An eco-friendly residence with energy-efficient systems and sustainable materials, promoting green living.",
    img: {
      url1: "Project-8.jpeg",
      url2: "Project-1.jpeg",
    },
    slug: "eco-friendly-residence",
  },
  {
    title: "Luxurious Villa ",
    area: "8500 sqft",
    location: "Dehradun, Uttrakhand ",
    service: "Architecture and Structure ",
    detailedDescription:
      "This eco-friendly residence is designed with sustainability in mind. It features energy-efficient systems, solar panels, and sustainable materials. The design promotes a healthy and environmentally-conscious lifestyle, making it an excellent choice for those who prioritize green living.",
    briefDescription:
      "An eco-friendly residence with energy-efficient systems and sustainable materials, promoting green living.",
    img: {
      url1: "Project-9.jpeg",
      url2: "Project-1.jpeg",
    },
    slug: "eco-friendly-residence",
  },
  {
    title: "Luxurious Villa ",
    area: "12000 sqft",
    location: "Kanpur, UP ",
    service: "Architecture and Structure ",
    detailedDescription:
      "This eco-friendly residence is designed with sustainability in mind. It features energy-efficient systems, solar panels, and sustainable materials. The design promotes a healthy and environmentally-conscious lifestyle, making it an excellent choice for those who prioritize green living.",
    briefDescription:
      "An eco-friendly residence with energy-efficient systems and sustainable materials, promoting green living.",
    img: {
      url1: "Project-10.jpeg",
      url2: "Project-1.jpeg",
    },
    slug: "eco-friendly-residence",
  },
  {
    title: "Luxurious Duplex",
    area: "3500 sqft",
    location: "Dehradun, Uttrakhand ",
    service: "Architecture and Structure ",
    detailedDescription:
      "This eco-friendly residence is designed with sustainability in mind. It features energy-efficient systems, solar panels, and sustainable materials. The design promotes a healthy and environmentally-conscious lifestyle, making it an excellent choice for those who prioritize green living.",
    briefDescription:
      "An eco-friendly residence with energy-efficient systems and sustainable materials, promoting green living.",
    img: {
      url1: "Project-11.jpeg",
      url2: "Project-1.jpeg",
    },
    slug: "eco-friendly-residence",
  },
  {
    title: "luxury interiors for restaurant",
    area: "5000 sqft",
    location: "mussoorie, Uttrakhand ",
    service: "Interiors",
    detailedDescription:
      "This eco-friendly residence is designed with sustainability in mind. It features energy-efficient systems, solar panels, and sustainable materials. The design promotes a healthy and environmentally-conscious lifestyle, making it an excellent choice for those who prioritize green living.",
    briefDescription:
      "An eco-friendly residence with energy-efficient systems and sustainable materials, promoting green living.",
    img: {
      url1: "Project-12.jpeg",
      url2: "Project-1.jpeg",
    },
    slug: "eco-friendly-residence",
  },
  {
    title: "luxury interiors for restaurant",
    area: "5000 sqft",
    location: "mussoorie, Uttrakhand ",
    service: "Interiors",
    detailedDescription:
      "This eco-friendly residence is designed with sustainability in mind. It features energy-efficient systems, solar panels, and sustainable materials. The design promotes a healthy and environmentally-conscious lifestyle, making it an excellent choice for those who prioritize green living.",
    briefDescription:
      "An eco-friendly residence with energy-efficient systems and sustainable materials, promoting green living.",
    img: {
      url1: "Project-13.jpeg",
      url2: "Project-1.jpeg",
    },
    slug: "eco-friendly-residence",
  },
];
