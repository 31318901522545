import React from "react";

export default function Type1() {
  return (
    <div className="flex flex-col gap-16">
      <div className="">
        <h1 className=" med-text font-medium">Building Design (A/S/MEPF)</h1>
        <p className=" mt-4 mb-2">
          AVINYA GREEN Constructions has collaborated with numerous esteemed international and
          regional firms, delivering projects across various sectors, including
          residential, commercial, mixed-use, senior living, studio apartments,
          village developments, single builds, multiplex developments, high
          rise, and skyscrapers. In the industrial sector, our expertise covers
          factories, oil & gas, pharmaceuticals, and manufacturing hubs.
        </p>
        <p>
          We have also made significant contributions in hospitality,
          healthcare, airports, railways & metros, roads & highways, bridges &
          tunnels, malls, podiums & stadiums, governmental buildings, army and
          military bases, specialized housing, smart city projects, and data
          centers.
        </p>
      </div>

      <div>
        <h1 className=" med-text font-medium flex">Infrastructure Design</h1>

        <div className=" mt-8 flex md:flex-row flex-col justify-between gap-4 pl-0.5 *:md:max-w-[50%]">
          <div>
            <img
              src="/assets/images/service-1.jpg"
              alt=""
              className=" max-w-[320px]"
            />
          </div>
          <div className=" mini-text flex items-center">
            We provides long-term, futuristic, environmentally friendly and
            sustainable infrastructure design services for the development of
            Transport, Water and Land with the use of advanced technologies to
            help save costs, quality improvement and prompt project delivery to
            our customers.
            <br /> <br />
            Our in-house design team is highly qualified & experienced in
            working on multi-disciplinary projects, giving them an edge when it
            comes to solving multi-faceted problems to minimize risk and develop
            creative, innovative & cost-effective solutions.
          </div>
        </div>
      </div>

      <div>
        <h1 className=" med-text font-medium">Prefabrication</h1>

        <div className=" mt-8 flex md:flex-row-reverse flex-col justify-between gap-4 pl-0.5 *:md:max-w-[50%]">
          <div>
            <img
              src="/assets/images/service-2.jpg"
              alt=""
              className=" max-w-[320px]"
            />
          </div>
          <div className=" mini-text flex items-center">
            A technical team of engineers, architects and draftsmen provides BIM
            modelling services for prefabricated building products and elements
            to enable off-site construction and reduce safety risks.
            <br />
            <br />
            With BIM modelling, we enable auto generated fabrication shop
            drawings for prefabricated building products as well as designs for
            precast and prefab components to save time and costs.
          </div>
        </div>
      </div>

      <div>
        <h1 className=" med-text font-medium">Pre Cast/Pre stressed Design</h1>

        <div className=" mt-8 flex md:flex-row flex-col justify-between gap-4 pl-0.5 *:md:max-w-[50%]">
          <div>
            <img
              src="/assets/images/service-3.jpg"
              alt=""
              className=" max-w-[320px]"
            />
          </div>
          <div className=" mini-text flex items-center">
            Our experience in Precast/ Pre-stressed concrete structures is
            extensive. We have designed and detailed structures such as parking
            garages, commercial and residential housing, warehouses, shopping
            malls and bridges.
          </div>
        </div>
      </div>
    </div>
  );
}
