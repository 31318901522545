import React from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import Lenis from "lenis";
import Home from "./pages/home/home";
import Contact from "./pages/contact/contact";
// import Team from "./pages/team/team";
import Projects from "./pages/projects/projects";
import ProjectDetail from "./pages/projects/ProjectDetail"; // Your new component
import About from "./pages/about/about";
import Navbar from "./comps/navbar";
import Footer from "./comps/footer";
import ScrollToTopOnMount from "./comps/ScrollToTopOnMount";
import Services from "./pages/services/services";
import Country from "./pages/country/country";
import Defense from "./pages/defense/defense";
// import PopUp from "./comps/popUp";

export default function App() {
  const location = useLocation();

  const lenis = new Lenis();
  function raf(time) {
    lenis.raf(time);
    requestAnimationFrame(raf);
  }

  requestAnimationFrame(raf);

  return (
    <>
      <ScrollToTopOnMount />
      {/* <PopUp /> */}
      <Navbar />
      <AnimatePresence mode="wait">
        <Routes location={location} key={location.pathname}>
          <Route index element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/projects" element={<Projects />} />
          <Route path="/services" element={<Services />} />
          <Route path="/projects/:slug" element={<ProjectDetail />} />
          <Route path="/country" element={<Country />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/defence" element={<Defense />} />
        </Routes>
      </AnimatePresence>
      <Footer />
    </>
  );
}
