import React, { useState } from "react";
import Type1 from "./comps/type1";
import Type2 from "./comps/type2";
import Type3 from "./comps/type3";

export default function Services() {
  const [active, setActive] = useState("1");

  const category = [
    {
      title: "Infrastructure Design",
      id: "1",
    },
    {
      title: "Information Modelling",
      id: "2",
    },
    {
      title: "QC/QA POLICY",
      id: "3",
    },
  ];

  const renderActiveContent = () => {
    switch (active) {
      case "1":
        return <Type1 />;
      case "2":
        return <Type2 />;
      case "3":
        return <Type3 />;
      default:
        return;
    }
  };

  return (
    <div className="sm:px-4 px-2">
      <h1 className="big-heading mb-12 flex items-end justify-center tracking-tight font-medium">
        Services
      </h1>
      <div className=" md:block hidden">
        <div className="flex flex-col gap-8">
          <div className="flex items-center justify-center gap-4 w-fit mx-auto rounded-full">
            {category.map((cat, i) => {
              return (
                <div
                  key={i}
                  className={`sm-text px-4 py-2 rounded-full flex items-center justify-center cursor-pointer ${
                    active === cat.id ? "bg-dark text-white" : ""
                  }`}
                  onClick={() => {
                    setActive(cat.id);
                  }}
                >
                  {cat.title}
                </div>
              );
            })}
          </div>
          <div className=" max-w-[1024px] w-full mx-auto *:p-4">
            {renderActiveContent()}
          </div>
        </div>
      </div>

      <div className="md:hidden flex flex-col gap-20">
        <div>
          <h1 className=" large-text font-medium mb-12">
            1) Building & Infrastructure Design
          </h1>
          <Type1 />
        </div>

        <div>
          <h1 className=" large-text font-medium mb-12">
            2) Building Information Modelling
          </h1>
          <Type2 />
        </div>

        <div>
          <h1 className=" large-text font-medium mb-12">3) QC/QA POLICY</h1>
          <Type3 />
        </div>
      </div>
    </div>
  );
}
